<template>
  <div class="ChallengeResultView">
    <div class="header">
      <div class="title">
        {{ $t(`${mode}.title`) }}
      </div>
      <div class="subtitle">
        {{ $t(`${mode}.subtitle`) }}
      </div>
      <br><br>
      <AvatarVsAvatar />
    </div>
    <div class="body">
      <div>
        <h2>Dbg section</h2>
        <div>me: {{ JSON.stringify(me.score) }}</div>
        <div>you: {{ JSON.stringify(you.score) }}</div>
        <!-- <div>{{ challenge.leaderboardsByChallengeIdList }}</div> -->
        <br>
        <div
          v-if="invitationUrl"
          @click.stop="copyInvitationUrl()">
          Copy invitation url
        </div>
        <br>
        <div v-if="me.score === null">
          You did not play yet.
          <button @click="dbgPlay()">
            Play now
          </button>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        class="continueButton --global-clickable"
        @click="exit()">
        {{ $t('continue') }}
      </div>
    </div>
  </div>
</template>

<translations>
  continue: 'Continue'
  pending:
    title: 'Pending Challenger'
    subtitle: 'We will notify you when the challenge is accepted'
</translations>

<script>
import store from '@/store';
import { copyStringToClipboard } from '@/utils';
import gameHelper from '@/modules/games-shared/game-helper';
import AvatarVsAvatar from '../components/AvatarVsAvatar';

export default {
    components: { AvatarVsAvatar },
    props: {
        gameId: {
            type: String,
            required: true
        },
        challengeId: {
            type: String,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.challenge.groupId);
        },
        challenge() {
            return this.$store.getters['moduleSocial/getChallenge'](this.challengeId);
            // return this.group.challengesByGroupIdList[0]
        },
        settings() {
            return JSON.parse(this.challenge.settings);
        },
        isPending() {
            return true;
        },
        // me () {
        //   return {
        //     //
        //   }
        // },
        // you () {
        //   return {
        //     //
        //   }
        // },
        me() {
            const result = this.challenge.leaderboardsByChallengeIdList.find(
                x => x.userId === this.$store.state.moduleSocial.userId
            );
            return {
                score: result ? result.score : null
            };
        },
        you() {
            // Assume: only one opponent
            const result = this.challenge.leaderboardsByChallengeIdList.find(
                x => x.userId !== this.$store.state.moduleSocial.userId
            );

            return {
                name: 'name of opponent',
                score: result ? result.score : null
            };
        },
        mode() {
            return 'pending';
        },
        invitationUrl() {
            if (!this.group) {
                return null;
            }
            const groupInvitation = this.group.groupInvitationsByGroupIdList[0];
            if (!groupInvitation) {
                return null;
            }
            return (
                location.origin +
                this.$router.resolve({
                    name: 'MemogeniusGame-ChallengeInvitation',
                    params: {
                        gameId: this.settings.gameId,
                        challengeId: this.challenge.id,
                        groupInvitationId: groupInvitation.id
                    }
                }).href
            );
        }
    },
    mounted() {
        // this.$store.dispatch('moduleSocial/refresh')
    },
    methods: {
        dbgPlay() {
            gameHelper.createChallengeSession({
                challengeId: this.challengeId,
                navigate: true
            });
        },
        copyInvitationUrl() {
            copyStringToClipboard(this.invitationUrl);
        },
        exit() {
            this.$router.push({
                name: 'MemogeniusGame-Challenges'
            });
        }
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch('moduleSocial/refresh');
        next();
    }
};
</script>

<style scoped lang="scss">
$sideMargin: 2em;

.ChallengeResultView {
    display: flex;
    flex-direction: column;
}
.header {
    padding-top: 3em;
}
.title {
    font-size: 130%;
    font-weight: 600;
    text-align: center;
}
.subtitle {
    color: rgba(white, 0.7);
    text-align: center;
    margin-top: 0.5em;
}

.body {
    flex: 1;
}

.footer {
    padding: 1em $sideMargin;
}

.continueButton {
    background-color: white;
    color: black;
    // color: white;
    padding: 1.5em 2em;
    border-radius: 3em;
    text-align: center;

    &.disabled {
        opacity: 0.2;
        cursor: default;
    }
}
</style>
